require("jquery");
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

window.autocomplete = function(form, element, list, should_submit) {
  const VALUE = 0;
  const DESCRIPTION = 1;
  var current_focus;



  document.addEventListener("click", function (e) {
    if (!(e.target.classList.contains('select-autocomplete'))) {
      close_autocomplete();
    }

  });

  element.addEventListener("input", function (e) {
    close_autocomplete();
    current_focus = -1;
    var text = this.value;
    findMatches(this.value);
  });
  element.addEventListener("click", function (e) {
    close_autocomplete();
    current_focus = -1;
    var text = this.value;
    findMatches(this.value);
  });

  function findMatches(text) {

    var to_add = [];
    for (var i = 0; i < list.length; i++) {
      if (list[i][DESCRIPTION].toUpperCase().includes(text.toUpperCase())) {
        to_add.push(list[i]);
      }
    }
    if (to_add.length > 0) {
      var a = document.createElement("DIV");
      a.setAttribute("class", "autocomplete-suggestions-div");
      element.parentNode.parentNode.getElementsByClassName("autocomplete-suggestions-div-wrapper")[0].appendChild(a);

      for (var i = 0; i < to_add.length; i++) {
        var b = document.createElement("DIV");
        b.innerHTML = to_add[i][DESCRIPTION];
        b.innerHTML += "<input type='hidden' value='" + to_add[i][VALUE] + "'>";

        b.setAttribute("class", "autocomplete-suggestion");

        b.addEventListener("click", function(e) {
            /*insert the value for the autocomplete text field:*/
            element.parentNode.parentNode.getElementsByClassName("select-value")[0].value = this.getElementsByTagName("input")[0].value;
            element.value = this.textContent;
            /*close the list of autocompleted values,
            (or any other open lists of autocompleted values:*/
            close_autocomplete(element);
            if (should_submit) {
              Rails.fire(form, 'submit');
            }
        });

        a.appendChild(b);
      }
    }
  }
  /*execute a function presses a key on the keyboard:*/
  element.addEventListener("keydown", function(e) {
      var x = element.parentNode.parentNode.getElementsByClassName("autocomplete-suggestions-div");

      if (!x) return false;
      x = x[0];
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        current_focus++;
        /*and and make the current item more visible:*/
        add_active(x);
      } else if (e.keyCode == 38) { //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        current_focus--;
        /*and and make the current item more visible:*/
        add_active(x);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (current_focus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[current_focus].click();
        }
        if (should_submit) {
          Rails.fire(form, 'submit');
        }
      }
  });

  function add_active(x) {
    /*a function to classify an item as "active":*/
    if (!x) return false;
    /*start by removing the "active" class on all items:*/
    remove_active(x);
    if (current_focus >= x.length) current_focus = 0;
    if (current_focus < 0) current_focus = (x.length - 1);
    /*add class "autocomplete-active":*/
    x[current_focus].classList.add("autocomplete-active");
  }
  function remove_active(x) {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("autocomplete-active");
    }
  }

  function close_autocomplete() {
    var list = document.getElementsByClassName('autocomplete-suggestions-div');
    for (var i = 0; i < list.length; i++) {
      list[i].remove();
    }
  }
}

window.activate = function(element) {
  $(element).addClass("active");
}
